import { Injectable } from '@angular/core';
import { Document } from '@model/document';
import { Order } from '@model/order';
import { Organisation } from '@model/organisation';
import { Permit } from '@model/permit';
import { KeycloakService } from 'keycloak-angular';
import * as _ from 'lodash';

import { HsvService } from './hsv.service';
import { ProfileService } from './profile.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  order: Order;

  constructor(
    private readonly hsvService: HsvService,
    private readonly profileService: ProfileService,
    private readonly sessionService: SessionService,
    private readonly keycloakService: KeycloakService,
  ) {
  }

  /**
   * Retrieve the current order.
   *
   * If the is no constiable containing the order yet, try and retrieve it from the session / local storage
   * @returns {*}
   */
  getOrder(): Order {
    if (_.isEmpty(this.order)) {
      if (this.sessionService.getOrder() != null) {
        this.order = this.sessionService.getOrder();
      } else {
        this.order = {
          hsvId: null,
          hsvSpecified: false,
        };
      }
    }

    return this.order;
  }

  updateOrderPermit(order: Order): void {
    const sessionOrder = this.getOrder();

    if (order.permit) {
      sessionOrder.permit = order.permit;
    } else if (sessionOrder.permit) {
      delete sessionOrder.permit;
    }

    if (order.additions) {
      sessionOrder.additions = order.additions;
    } else if (sessionOrder.additions) {
      delete sessionOrder.additions;
    }

    this.setOrder(sessionOrder);
  }

  setOrder(order: Order): void {
    this.order = {...this.order, ...order};

    // Only send needed information to the backend for session management
    const sessionOrder = this.fillSessionOrder(order);
    this.sessionService.setOrder(sessionOrder);
  }

  setHsvId(hsvId: number, specific: boolean): void {
    const order = this.getOrder();
    order.hsvId = hsvId;

    if (specific) {
      order.hsvSpecified = true;
    }

    const sessionOrder = this.fillSessionOrder(order);
    this.sessionService.setOrder(sessionOrder);
  }

  getHsvId(): number {
    const order = this.getOrder();
    if (order != null && order.hsvId != null) {
      return order.hsvId;
    }

    return null;
  }

  isAdditionOrder(): boolean {
    return !this.isPermitOrder();
  }

  /**
   * Return true if the order is for a 'permit' document:
   * VISPAS, EXTRA_VISPAS, JEUGD_VISPAS, JEUGD_VERGUNNING
   * @returns {boolean}
   */
  isPermitOrder(): boolean {
    const order = this.getOrder();

    if (order != null && order.permit != null) {
      if (
        order.permit.documentId === this.hsvService.getPermitId() ||
        order.permit.documentId === this.hsvService.getExtraPermitId() ||
        order.permit.documentId === this.hsvService.getYouthPermitId() ||
        order.permit.documentId === this.hsvService.getSeaPermitId() ||
        order.permit.documentId === this.hsvService.getYouthLicenseId()
      ) {
        return true;
      }
    }

    return false;
  }

  setResellerId(resellerId: number): void {
    console.debug(`Setting reseller with id : ${resellerId}`);
    const order = this.getOrder();
    order.resellerId = resellerId;

    const sessionOrder = this.fillSessionOrder(order);

    this.setOrder(sessionOrder);
  }


  getResellerId(): number {
    const order = this.getOrder();
    if (order != null && order.resellerId != null) {
      return order.resellerId;
    }

    return null;
  }

  setPermitType(documentId: number): void {
    const order = this.getOrder();

    if (!order.permit) {
      order.permit = {} as Permit;
    }

    if (documentId === 0 || documentId) {
      order.permit.documentId = documentId;
    }
    this.setOrder(order);
  }


  removeResellerId(): void {
    const order = this.getOrder();

    if (order.resellerId) {
      delete order.resellerId;
    }
    this.setOrder(order);
  }

  /**
   * Take our order object and fill our session object.
   *
   * @param order The order object
   * @returns The session object
   */
  fillSessionOrder(order: Order): Order {
    const sessionOrder = {} as Order;

    if (order) {

      if (order.permitNumber) {
        sessionOrder.permitNumber = order.permitNumber;
      }

      if (order.hsvId) {
        sessionOrder.hsvId = order.hsvId;
        if (order.hsvSpecified != null) {
          sessionOrder.hsvSpecified = order.hsvSpecified;
        }
      }

      if (order.resellerId) {
        sessionOrder.resellerId = order.resellerId;
      }

      if (order.invoiceId) {
        sessionOrder.invoiceId = order.invoiceId;
      }

      if (order.permit) {
        sessionOrder.permit = {};
        sessionOrder.permit.documentId = order.permit.documentId;
        sessionOrder.hsvSpecified = order.hsvSpecified;
      }

      if (order.additions && Array.isArray(order.additions)) {
        sessionOrder.additions = [];
        order.additions.forEach(additional => {
          const additionalOrder = {} as Document;
          additionalOrder.id = additional.id;
          additionalOrder.documentId = additional.documentId;
          sessionOrder.additions.push(additionalOrder);
        });
      }

      if (order.optionals && Array.isArray(order.optionals)) {
        sessionOrder.optionals = [];
        order.optionals.forEach(optional => {
          const optionalOrder = {} as Document;
          optionalOrder.id = optional.id;
          optionalOrder.documentId = optional.documentId;
          sessionOrder.optionals.push(optionalOrder);
        });
      }

      if (order.duplicate) {
        sessionOrder.duplicate = order.duplicate;
      }

      if (order.totalPrice) {
        sessionOrder.totalPrice = order.totalPrice;
      }
    }

    console.debug('SessionOrder: ', JSON.stringify(sessionOrder));

    return sessionOrder;
  }

  /**
   * Certain documents requires a user to have a VISpas,
   * therefor the user should login first, so we can retrieve the VISpas ID.
   */
  isLoginRequired(): boolean {
    if (this.order != null) {
      // Extra vispas
      if (this.order.permit != null && this.order.permit?.documentId === this.hsvService.getExtraPermitId()) {
        return true;
      } else if (this.order.permit == null && this.order.additions != null && this.order.additions.length > 0) {
        return true;
      }
    }

    return false;
  }

  isExtraPermitOrder(): boolean {
    const order = this.getOrder();

    return order != null && order.permit != null && order.permit.documentId === this.hsvService.getExtraPermitId();
  }


  isSmallPermitOrder(): boolean {
    const order = this.getOrder();

    return order != null && order.permit != null && order.permit.documentId === this.hsvService.getSmallPermitId();
  }

  isYouthLicenseOrder(): boolean {
    const order = this.getOrder();

    return order != null && order.permit != null && order.permit.documentId === this.hsvService.getYouthLicenseId();
  }

  isSeaFishingPermitOrder(): boolean {
    const order = this.getOrder();

    return order != null && order.permit != null && order.permit.documentId === this.hsvService.getSeaPermitId();
  }

  isValidYouthPermitOrder(): boolean {
    return (this.isYouthOrder() && this.isYouth());
  }

  isYouth(): boolean {
    return this.profileService.isYouth();
  }

  isYouthOrder(): boolean {
    const order = this.getOrder();

    return (order != null && order.permit != null && (order.permit.documentId === this.hsvService.getYouthPermitId() ||
      order.permit.documentId === this.hsvService.getYouthLicenseId()));
  }


  isDuplicateOrder(): boolean {
    const order = this.getOrder();

    if (order !== null && order.duplicate !== undefined) {
      return (order.duplicate);
    }

    return false;
  }

  isResellerOrder(): boolean {
    const order = this.getOrder();

    return order != null && order.resellerId != null;
  }

  isInvoiceOrder(): boolean {
    const order = this.getOrder();

    if (order != null) {
      return order.invoiceId != null;
    }

    return false;
  }

  async isValidOrder(): Promise<boolean> {
    const order = this.getOrder();
    const isLoggedIn = await this.keycloakService.isLoggedIn();
    let valid = true;

    if (isLoggedIn || this.profileService.getHsvId()) {

      // No duplicate small permit orders
      if (this.isSmallPermitOrder()) {
        if (this.profileService.hasMembership(this.hsvService.getSmallPermitId())) {
          valid = false;
        }
      }

      // No extra permit if there is no main membership
      if (this.isExtraPermitOrder() && !this.profileService.hasMainMembership()) {
        console.debug('Invalid extra permit order');
        valid = false;
      }

      // No addition order if there is no main membership
      if (this.isAdditionOrder() && order.additions && order.additions.length > 0) {

        if (this.profileService.getHsvId() && this.profileService.hadMainMembership()) {
          if (order.additions.some(addition => this.profileService.hasMembership(addition.documentId))) {
            valid = false;
          }
        } else {
          valid = false;
        }
      }
    }

    if (this.isYouthOrder()) {
      valid = this.isValidYouthPermitOrder();
    }

    return valid;
  }


  isHsvSpecificOrder(): boolean {
    const order = this.getOrder();

    return order != null && order.hsvSpecified != null && order.hsvSpecified;
  }

  clearOrder(): void {
    const order = {} as Order;
    this.order = order;
    this.sessionService.setOrder(order);
  }


  findOrganisationMembership(): Organisation {
    let mainMembershipOrganisation = {} as Organisation;

    if (this.profileService.hasMainMembership()) {
      mainMembershipOrganisation = this.profileService.getMainMembershipOrganisation();
    } else if (this.isAdditionOrder()) {
      mainMembershipOrganisation = this.profileService.getExpiredMainMembershipOrganisation();
    }

    if (mainMembershipOrganisation) {
      console.debug('Has main membership :', JSON.stringify(mainMembershipOrganisation));

      return mainMembershipOrganisation;
    }

    return null;
  }
}
