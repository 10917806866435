import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InfoMessage } from '@model/info-message';
import { Session } from '@model/session';
import { Observable, of } from 'rxjs';

import { BaseService } from './base.service';
import { SettingsCookieService } from './settings-cookie.service';

@Injectable({
  providedIn: 'root',
})
export class AbandonedCartService extends BaseService {

  constructor(
    private readonly settingsCookieService: SettingsCookieService,
    httpClient: HttpClient,
  ) {
    super(httpClient, 'funnel/cart');
  }

  updateAbandonedCart(session: Session): Observable<InfoMessage> {
    if (!this.settingsCookieService?.isMarketingEnabled()) {
      return of(null);
    }

    if (session?.cartUUID === undefined || session?.cartUUID === null || session?.cartUUID === '') {
      return this.httpClient.post<InfoMessage>(`${this.endpointUrl}`, session);
    } else {
      return this.httpClient.put<InfoMessage>(`${this.endpointUrl}`, session);
    }
  }

  getAbandonedCart(cartUuid: string): Observable<Session> {
    return this.httpClient.get<Session>(`${this.endpointUrl}/${cartUuid}`);
  }
}
