import { Injectable } from '@angular/core';
import { Language } from '@model/language';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@service/language.service';
import { Observable } from 'rxjs';

import { processLanguageCode } from '../utils/language-util';

@Injectable({
  providedIn: 'root',
})
export class TranslationInitializeService {
  constructor(
    private translateService: TranslateService,
    private languageService: LanguageService,
  ) {}

  initialize(): Observable<boolean> {
    const defaultLang: Language = processLanguageCode(
      localStorage.getItem('language') ??
        this.translateService.getBrowserLang(),
      this.languageService.getLanguages(),
    );
    localStorage.setItem('language', defaultLang.code);

    return new Observable<boolean>(observer => {
      this.translateService.use(defaultLang.code).subscribe({
        next: () => {
          observer.next(true);
          observer.complete();
        },
        error: error => {
          observer.error(error);
        },
      });
    });
  }
}
