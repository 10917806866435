import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '@service/navigation.service';
import { OrderService } from '@service/order.service';
import { SessionService } from '@service/session.service';

@Component({
  selector: 'svnl-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent implements OnInit {
  @Input() wizardStep: number;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly navigationService: NavigationService,
    private readonly orderService: OrderService,
    private readonly router: Router,
    private readonly sessionService: SessionService,
  ) {
  }

  ngOnInit(): void {
  }

  isActive(step: number): boolean {
    return step === this.wizardStep;
  }

  goToStep(step: number): void {
    if (this.isStepAllowed(step)) {
      this.navigationService.goStepstate(step);
    }
  }

  doUnderline(step: number): boolean {
    return step < this.wizardStep;
  }

  /**
   * Determine if a user is allowed to step to a given state, this is determined by the users'
   * progress in the order process. A user is only allowed to go to a given step, if he has completed the
   * previous steps.
   *
   * @param step The step to which to navigate to
   * @returns {boolean} True if allowed, false if not.
   */
  isStepAllowed(step: number): boolean {
    let allowed = this.wizardStep >= step || (step <= this.sessionService.getSessionStep());

    // Special flow for youth permit, some steps are skipped
    if (allowed && this.orderService.isYouthLicenseOrder()) {
      if (step === 3 || step === 4) {
        allowed = false;
      }
    }

    if (this.orderService.isInvoiceOrder()) {
      allowed = false;
    }

    if (this.orderService.isDuplicateOrder()) {
      allowed = false;
    }

    return allowed;
  }

  // Hide the navigation wizard from the view specified by route.
  isHidden(): boolean {
    return this.activatedRoute.snapshot.data && this.activatedRoute.snapshot.data['wizardHidden'];
  }

  goBack(): void {
    this.navigationService.previousStep();
  }

  showBackButton(): boolean {
    return (this.isHidden() || this.router.url !== '/order/permit');
  }

}
