import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { AddressCheckComponent } from './component/address-check/address-check.component';
import { BelgiumAddressCheckComponent } from './component/address-check/belgium-address-check.component';
import { GermanAddressCheckComponent } from './component/address-check/german-address-check.component';
import { CookiesComponent } from './component/cookies/cookies.component';
import { FooterComponent } from './component/footer/footer.component';
import { DebugFooterComponent } from './component/debug-footer/debug-footer.component';
import { HeaderHomeComponent } from './component/header-home/header-home.component';
import { HeaderComponent } from './component/header/header.component';
import { HsvHelpComponent } from './component/hsv-help/hsv-help.component';
import { LanguageDropdownComponent } from './component/language-dropdown/language-dropdown.component';
import { WizardComponent } from './component/wizard/wizard.component';
import { LoginDialogComponent } from './dialog/login-dialog/login-dialog.component';
import { RegisterDialogComponent } from './dialog/register-dialog/register-dialog.component';
import { CoupleAccountDialogComponent } from './dialog/warning/couple-account-dialog.component';
import { YearErrorDialogComponent } from './dialog/year-error-dialog/year-error-dialog.component';
import { YouthMessageDialogComponent } from './dialog/youth-message-dialog/youth-message-dialog.component';


const matModules = [
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatLuxonDateModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatToolbarModule,
  MatAutocompleteModule,
  MatDividerModule,
  MatBottomSheetModule,
];


@NgModule({
  declarations: [
    CookiesComponent,
    HeaderComponent,
    HeaderHomeComponent,
    DebugFooterComponent,
    FooterComponent,
    LanguageDropdownComponent,
    AddressCheckComponent,
    BelgiumAddressCheckComponent,
    GermanAddressCheckComponent,
    HsvHelpComponent,
    CoupleAccountDialogComponent,
    LoginDialogComponent,
    RegisterDialogComponent,
    YearErrorDialogComponent,
    YouthMessageDialogComponent,
    WizardComponent,
  ],
  exports: [
    CookiesComponent,
    HeaderComponent,
    HeaderHomeComponent,
    DebugFooterComponent,
    FooterComponent,
    AddressCheckComponent,
    BelgiumAddressCheckComponent,
    GermanAddressCheckComponent,
    HsvHelpComponent,
    CoupleAccountDialogComponent,
    LoginDialogComponent,
    RegisterDialogComponent,
    YearErrorDialogComponent,
    YouthMessageDialogComponent,
    RouterModule,
    TranslateModule,
    ReactiveFormsModule,
    ...matModules,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    ReactiveFormsModule,
    ...matModules,
    MatSlideToggleModule,
    NgOptimizedImage,
  ],
})
export class SharedModule {}
