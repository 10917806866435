<div class="svnl-header-wrapper">
  <mat-toolbar class="svnl-header">
    <img alt="logo van VISapp" class="svnl-header-logo" src="/assets/images/logo.png">
    <div class="svnl-header-dropdown">
      <svnl-language-dropdown></svnl-language-dropdown>
      <div *ngIf="showLoginButton && !isLoggedIn">
        <button (click)="login()" color="primary" mat-flat-button>
          <b translate="funnel.login.button.login"></b>
        </button>
      </div>
      <button (click)="logout()" *ngIf="isLoggedIn" color="warn" mat-flat-button>
        <b translate="funnel.generic.logout"></b>
      </button>
    </div>
  </mat-toolbar>

  <svnl-wizard [wizardStep]="wizardStep"></svnl-wizard>
</div>

