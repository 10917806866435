import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '@service/session.service';
import { KeycloakService } from 'keycloak-angular';

import { OrderService } from './order.service';
import { ProfileService } from './profile.service';

// noinspection JSIgnoredPromiseFromCall
@Injectable({
  providedIn: 'root',
})
export class NavigationService {

  wizardSteps: string[] = ['/order/permit', '/order/profile', '/order/hsv', '/order/optional', '/order/overview'];
  wizardStep: number;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly keycloakService: KeycloakService,
    private readonly orderService: OrderService,
    private readonly profileService: ProfileService,
    private readonly sessionService: SessionService,
  ) {
  }

  nextStep(wizardStep?: number): void {
    const currentUrl = this.currentUrl;

    if (wizardStep != null) {
      this.sessionService.setStep(wizardStep);
    }

    if (currentUrl.includes('home') || currentUrl.includes('info') || currentUrl.includes('termsconditions') || currentUrl.includes('behavioral')) {
      this.router.navigate(['order', 'permit']);
    } else if (currentUrl.includes('permit')) {
      if (this.orderService.isLoginRequired()) {
        this.checkLoginState();
      } else {
        this.router.navigate(['order', 'profile']);
      }
    } else {
      switch (currentUrl) {
        case '/login/required':
          this.router.navigate(['order', 'profile']);
          break;
        case '/order/profile':
          this.validateOrderState();
          break;
        case '/order/hsv':
          this.router.navigate(['order', 'optional']);
          break;
        case '/order/optional':
          this.router.navigate(['order', 'overview']);
          break;
        case '/order/overview':
          this.router.navigate(['order', 'payment', 'select']);
          break;
        case '/order/payment/select':
          this.router.navigate(['order', 'payment', 'success']);
          break;
        case '/error':
          this.router.navigate(['order', 'permit']);
          break;
      }
    }

  }

  previousStep(): void {
    if (this.orderService.isInvoiceOrder() || this.orderService.isDuplicateOrder()) return;

    switch (this.currentUrl) {
      case '/order/profile':
        this.router.navigate(['order', 'permit']);
        break;
      case '/order/hsv':
        this.router.navigate(['order', 'profile']);
        break;
      case '/order/optional':
        if (this.orderService.isSmallPermitOrder()) {
          this.router.navigate(['order', 'profile']);
        } else {
          this.router.navigate(['order', 'hsv']);
        }
        break;
      case '/order/overview':
        if (this.orderService.isSmallPermitOrder()) {
          this.router.navigate(['order', 'profile']);
        } else {
          this.router.navigate(['order', 'optional']);
        }
        break;
      case '/order/payment/select':
        this.router.navigate(['order', 'overview']);
        break;
      case '/login/required':
        this.router.navigate(['order', 'permit']);
        break;
      case '/login/optional':
        this.router.navigate(['order', 'profile']);
        break;
      case '/payment/select':
        this.router.navigate(['order', 'optional']);
        break;
      case '/payment/failed':
        this.router.navigate(['order', 'select']);
        break;
      case '/order/youth':
        this.router.navigate(['order', 'profile']);
        break;
      case '/info':
      case '/termsconditions':
      case '/behavioral':
      case '/error':
        this.router.navigate(['home']);
        break;
    }
  }

  goHome(): void {
    this.router.navigate(['home']);
  }

  goStepstate(step: number): void {
    this.router.navigateByUrl(this.wizardSteps[step - 1]);
  }

  private checkLoginState(): void {
    this.keycloakService.isLoggedIn().then(isLoggedIn => {
      if (this.orderService.isLoginRequired() && (!isLoggedIn || this.profileService.getHsvId() === undefined)) {
        this.router.navigate(['login', 'required']);
      } else {
        this.router.navigate(['order', 'profile']);
      }
    });
  }

  private async validateOrderState(): Promise<void> {
    if (this.orderService.isYouthLicenseOrder() && await this.orderService.isValidOrder()) {
      await this.router.navigate(['/order', 'youth']);
    } else if (this.orderService.isSmallPermitOrder() && await this.orderService.isValidOrder()) {
      await this.router.navigate(['/order', 'optional']);
    } else if (this.orderService.isAdditionOrder()) {
      if (await this.orderService.isValidOrder()) {
        await this.router.navigate(['/order', 'optional']);
      } else {
        await this.router.navigate(['/order', 'permit']);
      }
    } else {
      await this.router.navigate(['/order', 'hsv']);
    }
  }

  private get currentUrl(): string {
    const url = this.router.url;

    return url.split('?')[0];
  }
}
