import { Injectable } from '@angular/core';
import { Language, languageCodeList } from '@model/language';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  languages: Language[] = languageCodeList.map(languageCode => ({
    code: languageCode,
    label: `funnel.generic.language.select.${languageCode.toUpperCase()}`,
    image: `/assets/images/flags/${languageCode.toUpperCase()}.png`,
  }));

  private language: Language = this.languages[0];

  constructor(private translateService: TranslateService) {}

  getLanguage(): Language {
    this.language = this.languages.find(
      lang => localStorage.getItem('language') === lang.code,
    );

    return this.language;
  }

  getLanguages(): Language[] {
    return this.languages;
  }

  setLanguage(code: string): void {
    this.language = this.languages.find(lang => code === lang.code);
    this.translateService.use(code).subscribe();
    localStorage.setItem('language', code);
  }
}
