import { Injectable } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Document } from '@model/document';

import { CookiesComponent } from '../component/cookies/cookies.component';
import { LoginDialogComponent } from '../dialog/login-dialog/login-dialog.component';
import { RegisterDialogComponent } from '../dialog/register-dialog/register-dialog.component';
import { CoupleAccountDialogComponent } from '../dialog/warning/couple-account-dialog.component';
import { YearErrorDialogComponent } from '../dialog/year-error-dialog/year-error-dialog.component';
import { YouthMessageDialogComponent } from '../dialog/youth-message-dialog/youth-message-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {

  constructor(
    private readonly matDialog: MatDialog,
    private readonly matBottomSheet: MatBottomSheet,
  ) {
  }

  openLoginDialog(): MatDialogRef<LoginDialogComponent, boolean> {
    return this.matDialog.open(LoginDialogComponent);
  }

  openRegisterDialog(): MatDialogRef<RegisterDialogComponent, boolean> {
    return this.matDialog.open(RegisterDialogComponent);
  }

  openYearErrorDialog(): MatDialogRef<YearErrorDialogComponent, boolean> {
    return this.matDialog.open(YearErrorDialogComponent);
  }

  openYouthMessageDialog(eligible: boolean, document?: Document): MatDialogRef<YouthMessageDialogComponent, boolean> {
    return this.matDialog.open(YouthMessageDialogComponent, {
      data: {
        eligible,
        document,
      },
    });
  }

  openCookieFooter(): MatBottomSheetRef<CookiesComponent, any> {
    return this.matBottomSheet.open(CookiesComponent);
  }

  openCoupleAccountDialog(): MatDialogRef<CoupleAccountDialogComponent, boolean> {
    return this.matDialog.open(CoupleAccountDialogComponent);
  }

}
