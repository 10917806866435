/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OrderService } from '@service/order.service';
import { ResellerService } from '@service/reseller.service';
import { SettingsService } from '@service/settings.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentGuard implements CanActivate {

  constructor(
    private readonly router: Router,
    private readonly orderService: OrderService,
    private readonly settingsService: SettingsService,
    private readonly resellerService: ResellerService,
  ) {
  }

  /**
   * Handle document-related parameters, set order type and documents accordingly and redirect the user the corrected starting page for that specific flow.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.orderService.clearOrder();

    const resellerId = parseInt(route.queryParams['vkp']);
    const hsvId = parseInt(route.queryParams['hsvid']);
    const documentId = parseInt(route.queryParams['documentid']);

    if (documentId === 0 || (documentId && !isNaN(documentId))) {
      this.orderService.setPermitType(documentId);
    }

    if (hsvId && !isNaN(hsvId)) {
      this.orderService.setHsvId(hsvId, true);

      return this.settingsService.getSettingsForOrganisation(hsvId).pipe(map(() => {
        this.router.navigate(['/order', 'permit']);

        return false;
      }));
    } else if (resellerId && !isNaN(resellerId)) {
      return this.resellerService.setReseller(resellerId).pipe(map(reseller => {
        if (reseller) {
          this.orderService.setResellerId(resellerId);
        }

        return true;
      }));
    } else if (documentId === 0 || (documentId && !isNaN(documentId))) {
      this.router.navigate(['/order', 'permit']);

      return false;
    } else {
      return true;
    }
  }

}
