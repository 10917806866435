import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'svnl-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent {

  constructor(
    private readonly keycloakService: KeycloakService,
  ) {
  }

  goLogin(): void {
    this.keycloakService.login({ 'action': 'LOGIN' });
  }
}
