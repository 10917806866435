import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'svnl-register-dialog',
  templateUrl: './register-dialog.component.html',
  styleUrls: ['./register-dialog.component.scss'],
})
export class RegisterDialogComponent {

  constructor(
    private readonly keycloakService: KeycloakService,
  ) {
  }

  goLogin(): void {
    this.keycloakService.login({ 'action': 'LOGIN' });
  }
}
