export enum PaymentServiceType {
  IDEAL = 'IDEAL',
  BANCONTACT = 'BANCONTACT',
  SOFORT = 'SOFORT',
  CREDITCARD = 'CREDITCARD',
  PAYPAL = 'PAYPAL',
  VISACARD = 'VISACARD',
  GIROPAY = 'GIROPAY',
  P24 = 'P24',
  APPLEPAY = 'APPLEPAY',
  UNKNOWN = '',
}

export interface Payment {
  service?: PaymentServiceType;
  bank?: string;
  bic?: string;
  debit?: boolean;
  debitIban?: string;
  debitBic?: string;
  name?: string;
  applePayToken?: string;
}
