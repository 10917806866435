<h2>{{'funnel.modal.login.title' | translate}}</h2>
<mat-dialog-content>
  <p>{{'funnel.modal.login.loginExists' | translate}}</p>
  <p>{{'funnel.modal.login.text' | translate}}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close>
    {{'funnel.modal.login.back' | translate}}
  </button>
  <button mat-flat-button color="primary" (click)="goLogin()">
    {{'funnel.modal.login.button' | translate}}
  </button>
</mat-dialog-actions>

