/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DuplicateService } from '@service/duplicate.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DuplicateGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly duplicateService: DuplicateService,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const duplicateid = route.queryParams['duplicateid'];
    const documentid = parseInt(route.queryParams['documentid']);

    if (duplicateid != null && documentid != null && !isNaN(documentid) ) {
      this.duplicateService.createDuplicateOrder(duplicateid, documentid)
        .subscribe(duplicateOrder => {
          if (duplicateOrder) {
            this.router.navigate(['/order', 'overview']);
          }
        });
    }

    return true;
  }

}
