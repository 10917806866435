<mat-dialog-content>
  <h2>{{'funnel.modal.warning.couple.title' | translate}}</h2>
  <p>
    <span [innerHTML]="'funnel.permit.warning.coupleAccount' | translate"></span>
  </p>
</mat-dialog-content>

<div class="svnl-couple-dialog">
  <button mat-flat-button color="primary" (click)="goCouple()">
    <label>{{'funnel.permit.warning.couple' | translate}}</label>
  </button>
  <button mat-stroked-button mat-dialog-close color="warn" (click)="close()">
    <label>{{'funnel.permit.warning.continue' | translate}}</label>
  </button>
</div>


