import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Document } from '@model/document';
import { TranslateService } from '@ngx-translate/core';
import { HsvService } from '@service/hsv.service';
import { NavigationService } from '@service/navigation.service';
import { OrderService } from '@service/order.service';
import { ProfileService } from '@service/profile.service';
import { SettingsService } from '@service/settings.service';

export interface YouthDialogData {
  eligible: boolean;
  document: Document;
  documentLabel: '';
}

@Component({
  selector: 'svnl-youth-message-dialog',
  templateUrl: './youth-message-dialog.component.html',
  styleUrls: ['./youth-message-dialog.component.scss'],
})
export class YouthMessageDialogComponent implements OnInit {
  document: Document;
  error: string;
  documentLabel: '';

  constructor(
    private readonly orderService: OrderService,
    private readonly settingsService: SettingsService,
    private readonly profileService: ProfileService,
    private readonly hsvService: HsvService,
    private readonly navigationService: NavigationService,
    private readonly translateService: TranslateService,
    private readonly matDialogRef: MatDialogRef<YouthMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: YouthDialogData,
  ) {
  }

  ngOnInit(): void {
    this.getSuggestedPermit();
  }

  get isEligible(): boolean {
    return this.data.eligible;
  }

  accept(): void {
    this.orderService.setPermitType(this.document.documentId);
    this.navigationService.nextStep();
    this.matDialogRef.close(true);
  }

  cancel(): void {
    this.matDialogRef.close(false);
  }

  private async getSuggestedPermit() {
    let documentId: number;

    if (this.orderService.isYouthOrder() && !(await this.orderService.isValidOrder())) {
      if (this.profileService.hasMainMembership()) {
        documentId = this.hsvService.getExtraPermitId();
      } else {
        documentId = this.hsvService.getPermitId();
      }
    } else if (!this.orderService.isYouthOrder() && this.profileService.isYouth()) {
      documentId = this.hsvService.getYouthPermitId();
    }

    this.settingsService.getDocuments().subscribe({
      next: documents => {
        this.findDocument(documentId, documents);
      },
      error: err => {
        console.error('[YouthMessageDialogComponent] error fetching documents', err);
        this.error = 'funnel.error.loading.documents';
      },
    });
  }

  private findDocument(documentId: number, documents: Document[]): void {
    if (documentId != null) {
      this.document = documents.find(document => document.documentId === documentId);
      if (documentId === this.hsvService.getPermitId()) {
        this.documentLabel = this.translateService.instant('funnel.permit.label.vispas');
      } else if (documentId === this.hsvService.getExtraPermitId()) {
        this.documentLabel = this.translateService.instant('funnel.permit.label.extravispas');
      }
    }
  }
}
