import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslationInitializeService } from '@service/translation-initialize.service';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';


const initTranslations = (translations: TranslationInitializeService) => () =>
  translations.initialize().subscribe();

const initKeycloak = (keycloak: KeycloakService) => () =>
  keycloak.init({
    config: {
      url: environment.keycloak.url,
      realm: environment.keycloak.realm,
      clientId: environment.keycloak.clientId,
    },
    initOptions: environment.keycloak.initOptions,
  });

const multiTranslateHttpLoader = (http: HttpClient) => new MultiTranslateHttpLoader(
  http, [
    { prefix: './assets/translate/', suffix: '.json' },
    { prefix: './assets/translate/countries/', suffix: '.json' },
    { prefix: './assets/translate/termsconditions/', suffix: '.json' },
    { prefix: './assets/translate/behavioral/', suffix: '.json' },
  ],
);

const analytics = [];

if (environment.analytics.gtm) {
  analytics.push(GoogleTagManagerModule.forRoot({
    id: environment.analytics.gtm,
  }));
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    MatSnackBarModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: multiTranslateHttpLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'nl',
    }),
    SharedModule,
    HttpClientModule,
    ...analytics,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initTranslations,
      multi: true,
      deps: [TranslationInitializeService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'nl',
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {
}
