import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { LanguageService } from '@service/language.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageParamGuard implements CanActivate {
  constructor(
    private readonly languageService: LanguageService,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const languageQueryParameter = route.queryParams['lang'];
    if (languageQueryParameter) {
      this.languageService.setLanguage(languageQueryParameter);
    }

    return true;
  }

}
