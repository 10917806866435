<div class="svnl-wizard-background" *ngIf="!isHidden()">
  <div class="header-wizard-row">
    <ul class='nav nav-wizard'>
      <li
        [ngClass]="{'active': isActive(1),'allowed':isStepAllowed(1) && !isActive(1), 'disabled':!isStepAllowed(1), 'underline': doUnderline(1)}">
        <span (click)="goToStep(1)" translate="funnel.header.step.one"></span>
      </li>
      <li
        [ngClass]="{'active': isActive(2), 'allowed':isStepAllowed(2) && !isActive(2), 'disabled':!isStepAllowed(2), 'underline': doUnderline(2) }">
        <span (click)="goToStep(2)" translate="funnel.header.step.two"></span>
      </li>
      <li
        [ngClass]="{'active': isActive(3), 'allowed':isStepAllowed(3) && !isActive(3), 'disabled':!isStepAllowed(3), 'underline': doUnderline(3) }">
        <span (click)="goToStep(3)" translate="funnel.header.step.three"></span>
      </li>
      <li
        [ngClass]="{'active': isActive(4), 'allowed':isStepAllowed(4) && !isActive(4), 'disabled':!isStepAllowed(4), 'underline': doUnderline(4) }">
        <span (click)="goToStep(4)" translate="funnel.header.step.four"></span>
      </li>
      <li
        [ngClass]="{'active': isActive(5), 'allowed':isStepAllowed(5) && !isActive(5), 'disabled':!isStepAllowed(5) }">
        <span (click)="goToStep(5)" translate="funnel.header.step.five"></span>
      </li>
    </ul>
  </div>
</div>
