import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Organisation } from '@model/organisation';
import { Organisations } from '@model/organisations';
import { Reseller } from '@model/reseller';
import { map, Observable } from 'rxjs';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class HsvService extends BaseService {

  constructor(
    httpClient: HttpClient,
  ) {
    super(httpClient, 'funnel');
  }

  findOrganisationsByCity(city: string): Observable<Organisation[]> {
    return this.httpClient.get<Organisations>(`${this.endpointUrl}/hsv/cities/${city}`)
      .pipe(
        map(value => value.organisations),
      );
  }

  /**
   * Return an Organisation
   *
   * @param hsvId The HsvId of the organisation
   * @returns organisationDto object
   */
  findByHsvId(hsvId: number): Observable<Organisation> {
    return this.httpClient.get<Organisation>(`${this.endpointUrl}/hsv/hsvId/${hsvId}`);
  }

  getCities(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.endpointUrl}/hsv/cities/`);
  }

  findResellerById(resellerId: number): Observable<Reseller> {
    return this.httpClient.get<Reseller>(`${this.endpointUrl}/reseller/${resellerId}`);
  }

  getSmallPermitOrganisationId(): number {
    return 1;
  }

  getSmallPermitId(): number {
    return 0;
  }

  getPermitId(): number {
    return 1;
  }

  getExtraPermitId(): number {
    return 3;
  }

  getYouthPermitId(): number {
    return 2;
  }

  getSeaPermitId(): number {
    return 4;
  }

  getNightFishingAdditionId(): number {
    return 5;
  }

  getThirdRodAdditionId(): number {
    return 6;
  }

  getYouthLicenseId(): number {
    return 20;
  }

  getMagazineDocumentId(): number {
    return 9;
  }

  getFishingWaterListDocumentId(): number {
    return 24;
  }

  getSmallPermitFishingWaterListDocumentId(): number {
    return 29;
  }

  getShippingCostsDocumentId(): number {
    return 30;
  }

  getAdministrationCostDocumentId(): number{
    return 31;
  }

  getNewsletterDocumentId(): number {
    return 1001;
  }

}
