<h1 *ngIf="isEligible" mat-dialog-title [innerHTML]="'funnel.modal.youth.eligible.title' | translate"></h1>
<h1 *ngIf="!isEligible" mat-dialog-title [innerHTML]="'funnel.modal.youth.not.eligible.title' | translate"></h1>
<div mat-dialog-content>
  <p *ngIf="isEligible" [innerHTML]="'funnel.modal.youth.eligible.body' | translate"></p>
  <div *ngIf="!isEligible">
    <p [innerHTML]="'funnel.modal.youth.not.eligible.body' | translate"></p>
    <p><b>{{this.documentLabel}}</b></p>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="cancel()">
    {{ 'funnel.generic.back' | translate}}
  </button>
  <button (click)="accept()" color="primary" mat-flat-button>
    <span *ngIf="isEligible" translate="funnel.modal.youth.button.change"></span>
    <span *ngIf="!isEligible" translate="funnel.profile.modal.change"></span>
  </button>
</div>
