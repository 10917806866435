<form (ngSubmit)="save()" [formGroup]="cookiesForm">
  <h4 translate="funnel.cookies.title"></h4>
  <p [innerHTML]="'funnel.cookies.body' | translate "></p>

  <mat-divider></mat-divider>

  <p translate="funnel.cookies.functional.body"></p>
  <mat-slide-toggle color="primary" formControlName='settingsFunctional'>
    <p translate="funnel.generic.on"></p>
  </mat-slide-toggle>

  <mat-divider></mat-divider>

  <p translate="funnel.cookies.tracking.body"></p>
  <mat-slide-toggle (change)="trackingChanged($event)" color="primary" formControlName='settingsTracking'>
    <p *ngIf="settings.tracking" translate="funnel.generic.on"></p>
    <p *ngIf="!settings.tracking" translate="funnel.generic.off"></p>
  </mat-slide-toggle>

  <mat-divider></mat-divider>

  <p translate="funnel.cookies.marketing.body"></p>
  <mat-slide-toggle (change)="marketingChanged($event)" color="primary" formControlName='settingsMarketing'>
    <p *ngIf="settings.marketing" translate="funnel.generic.on"></p>
    <p *ngIf="!settings.marketing" translate="funnel.generic.off"></p>
  </mat-slide-toggle>

  <mat-divider></mat-divider>

  <br>

  <button color="primary" mat-flat-button type="submit">
    <b translate="funnel.generic.save"></b>
  </button>
</form>
