import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Document } from '@model/document';
import { Documents } from '@model/documents';
import { map, Observable } from 'rxjs';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService extends BaseService {
  constructor(
    httpClient: HttpClient,
  ) {
    super(httpClient, 'funnel');
  }

  getRemoteDefaultDocuments(): Observable<Document[]> {
    return this.httpClient.get<Documents>(`${this.endpointUrl}/hsv/documents/`).pipe(map(documents => documents.documents));
  }

  getRemoteDefaultDocumentsForOrganisation(organisationId: number): Observable<Document[]> {
    return this.httpClient.get<Documents>(`${this.endpointUrl}/hsv/documents/${organisationId}?inactives=false`).pipe(map(documents => documents.documents));
  }
}
