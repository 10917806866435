import { Document } from './document';

export enum MagazineType {
  NOT = 'NOT',
  SEPARATE = 'SEPARATE',
  INCLUDED = 'INCLUDED',
}

export interface Settings {
  documents?: Document[],
  debitType?: string,
  magazineType?: string,
  show?: boolean,
  tracking?: boolean,
  marketing?: boolean,
  functional?: boolean,
  value?: string,
  fishingWaterListType?: string,
}
