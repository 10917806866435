import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

export abstract class BaseService {

  protected endpointUrl: string;
  protected headers = new HttpHeaders();

  protected constructor(
    protected httpClient: HttpClient,
    protected path: string,
  ) {
    this.endpointUrl = `${environment.api}/${this.path}`;
  }
}
