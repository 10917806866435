import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DebitType } from '@model/debittype';
import { Document } from '@model/document';
import { MagazineType, Settings } from '@model/settings';
import { TranslateService } from '@ngx-translate/core';
import { concatMap, map, Observable, of, switchMap, tap } from 'rxjs';


import { BaseService } from './base.service';
import { DocumentsService } from './documents.service';
import { OrderService } from './order.service';
import { SessionService } from './session.service';

enum DocumentType {
  VISPAS = 'VISPAS',
  KLEINE_VISPAS = 'KLEINE_VISPAS',
  EXTRA_VISPAS = 'EXTRA_VISPAS',
  JEUGD_VISPAS = 'JEUGD_VISPAS',
  ZEE_VISPAS = 'ZEE_VISPAS',
  JEUGD_VERGUNNING = 'JEUGD_VERGUNNING',
  NACHTVISSEN = 'NACHTVISSEN',
  DERDE_HENGEL = 'DERDE_HENGEL',
  VISWATERLIJST = 'VISWATERLIJST',
  VISBLAD = 'VISBLAD',
  NIEUWSBRIEF = 'NIEUWSBRIEF',
  VERZENDKOSTEN = 'VERZENDKOSTEN',
}


@Injectable({
  providedIn: 'root',
})
export class SettingsService extends BaseService {
  settings: Settings = {
    documents: [],

    // OPTIONAL, DISABLED, REQUIRED
    debitType: DebitType.REQUIRED,

    // NOT, SEPARATE, INCLUDED
    magazineType: MagazineType.SEPARATE,
    show: null,
    tracking: null,
    marketing: null,
    value: null,
  };

  defaultDocuments: Document[];
  selectedHsv = 0;

  constructor(
    private readonly storageService: SessionService,
    private readonly orderService: OrderService,
    private readonly documentsService: DocumentsService,
    private readonly translateService: TranslateService,
    httpClient: HttpClient,
  ) {
    super(httpClient, 'funnel/hsv/settings');
  }

  /**
     * Retrieve the set of documents for a given organisation ID. Each HSV has his own set of
     * documents it offers, with their own pricing.
     *
     * @param organisationId The organisation ID for which to retrieve the documents
     * @param allowInactive Allow inactive HSVs
     * @returns organisation settings (including documents);
     */
  getSettingsForOrganisation(organisationId: number, allowInactive?: boolean): Observable<Settings> {
    if (organisationId === undefined) {
      console.warn('[SettingsService] getSettingsForOrganisation organisationId is empty, should not happen');

      return of(null);
    }

    if (allowInactive == null) {
      allowInactive = false;
    }

    if (this.settings && this.settings.documents.length > 0) {
      if (organisationId === this.selectedHsv) {
        console.debug('Already have organisation documents');

        return of(this.settings);
      } else {
        return this.getSettingsAndUpdate(organisationId, allowInactive);
      }
    } else {
      console.debug(`No settings / Document list is empty, selected hsv = ${this.selectedHsv}`);
      /** If we have no default documents and our selected HSV id is empty, this means we are at the HSV selection
             * and the user has refreshed. First we need to retrieve the default document set (Or maybe from the session?)
             */
      if (this.selectedHsv !== 0) {
        return this.getDefaultRemoteSettingsAndUpdate(organisationId, allowInactive);
      } else {
        return this.getSettingsAndUpdate(organisationId, allowInactive);
      }
    }
  }


  getDocuments(): Observable<Document[]> {
    if (this.settings != null && this.settings.documents != null && this.settings.documents.length > 0) {
      return of(this.settings.documents);
    } else {
      if (this.orderService.getHsvId()) {
        const hsvId = this.orderService.getHsvId();

        return this.getSettingsForOrganisation(hsvId).pipe(map(value => value.documents));
      } else {
        return this.getDefaultDocuments();
      }
    }
  }

  getDefaultDocuments(): Observable<Document[]> {
    if (this.defaultDocuments != null && this.defaultDocuments.length > 0) {
      return of(this.defaultDocuments);
    } else {
      return this.documentsService.getRemoteDefaultDocuments();
    }
  }

  getSettings(): Observable<Settings> {
    if (this.settings && this.settings.documents.length > 0) {
      return of(this.settings);
    } else {
      if (this.orderService.getHsvId()) {
        const hsvId = this.orderService.getHsvId();

        return this.getSettingsForOrganisation(hsvId);
      } else {
        return of(this.settings);
      }
    }
  }

  private getSettingsAndUpdate(organisationId: number, allowInactive: boolean): Observable<Settings> {
    if (this.defaultDocuments === undefined || this.defaultDocuments.length === 0) {
      return this.getDefaultRemoteSettingsAndUpdate(organisationId, allowInactive);
    } else {
      return this.getRemoteSettingsAndUpdate(organisationId, allowInactive);
    }
  }

  private getRemoteSettingsAndUpdate(organisationId: number, allowInactive: boolean): Observable<Settings> {
    return this.getRemoteSettingsForOrganisation(organisationId, allowInactive)
      .pipe(
        map(settings => this.updateSettingsAndMapDocuments(settings, organisationId)),
      );
  }

  private getDefaultRemoteSettingsAndUpdate(organisationId: number, allowInactive: boolean): Observable<Settings> {
    return this.getDefaultDocuments()
      .pipe(
        concatMap(() => this.getRemoteSettingsAndUpdate(organisationId, allowInactive)),
      );
  }

  private getRemoteSettingsForOrganisation(organisationId: number, allowInactive: boolean): Observable<Settings> {
    const config = {
      headers: {
        'accept': 'application/json',
      },
      params: {
        inactives: allowInactive,
      },
    };

    return this.httpClient.get<Settings>(`${this.endpointUrl}/${organisationId}`, config);
  }

  /**
     * Update our current set of documents with the new set. Documents that are in the
     * new set, but not in the current list will NOT be included. This is to prevent users
     * from selecting an HSV, selecting a specific document and switch HSV again to a HSV which
     * does not sell the selected document.
     *
     * @param newDocuments The new set of documents
     * @param currentDocuments The current list of documents
     */
  private updateDocumentList(newDocuments: Document[], currentDocuments: Document[]): Document[] {
    const filteredDocuments = currentDocuments
      .filter(currentDocument => newDocuments.find(newDocument => newDocument.documentId === currentDocument.documentId));

    return [...filteredDocuments, ...newDocuments];
  }

  private updateSettingsAndMapDocuments(settings: Settings, organisationId: number): Settings {
    this.selectedHsv = organisationId;
    if (!settings.documents) {
      return null;
    }

    if (settings.documents.length > 0) {
      let updatedDocuments: Document[];

      if (this.settings && this.settings.documents.length > 0) {
        updatedDocuments = this.updateDocumentList(settings.documents, this.defaultDocuments);
      }
      let mappedDocuments: Document[];
      if (updatedDocuments !== undefined) {
        mappedDocuments = updatedDocuments.map(updateDocument => this.mapPermitLocale(updateDocument));
        if (updatedDocuments != null) {
          mappedDocuments = updatedDocuments.map(this.mapPermitLocale);
        } else {
          mappedDocuments = settings.documents.map(settingDocument => this.mapPermitLocale(settingDocument));
        }

        this.settings = settings;
        this.settings.documents = mappedDocuments;
      }
    }

    return settings;
  }

  private mapPermitLocale(document: Document): Document {
    const localDocument = { ...document };

    switch (localDocument.id) {
      case DocumentType.VISPAS:
        localDocument.label = this.translateService.instant('funnel.permit.label.vispas');
        localDocument.info = this.translateService.instant('funnel.permit.info.vispas');
        break;
      case DocumentType.KLEINE_VISPAS:
        localDocument.label = this.translateService.instant('funnel.permit.label.kleinevispas');
        localDocument.info = this.translateService.instant('funnel.permit.info.kleinevispas');
        break;
      case DocumentType.EXTRA_VISPAS:
        localDocument.label = this.translateService.instant('funnel.permit.label.extravispas');
        localDocument.info = this.translateService.instant('funnel.permit.info.extravispas');
        break;
      case DocumentType.JEUGD_VISPAS:
        localDocument.label = this.translateService.instant('funnel.permit.label.jeugdvispas');
        localDocument.info = this.translateService.instant('funnel.permit.info.jeugdvispas');
        break;
      case DocumentType.ZEE_VISPAS:
        localDocument.label = this.translateService.instant('funnel.permit.label.zeevispas');
        localDocument.info = this.translateService.instant('funnel.permit.info.zeevispas');
        break;
      case DocumentType.JEUGD_VERGUNNING:
        localDocument.label = this.translateService.instant('funnel.permit.label.jeugdvergunning');
        localDocument.info = this.translateService.instant('funnel.permit.info.jeugdvergunning');
        break;
      case DocumentType.NACHTVISSEN:
        localDocument.label = this.translateService.instant('funnel.permit.label.nachtvissen');
        localDocument.info = this.translateService.instant('funnel.permit.info.nachtvissen');
        break;
      case DocumentType.DERDE_HENGEL:
        localDocument.label = this.translateService.instant('funnel.permit.label.derdehengel');
        localDocument.info = this.translateService.instant('funnel.permit.info.derdehengel');
        break;
      case DocumentType.VISWATERLIJST:
        localDocument.label = this.translateService.instant('funnel.permit.label.viswaterlijst');
        localDocument.info = this.translateService.instant('funnel.permit.info.viswaterlijst');
        break;
      case DocumentType.VISBLAD:
        localDocument.label = this.translateService.instant('funnel.permit.label.visblad');
        localDocument.info = this.translateService.instant('funnel.permit.info.visblad');
        break;
      case DocumentType.NIEUWSBRIEF:
        localDocument.label = this.translateService.instant('funnel.permit.label.nieuwsbrief');
        localDocument.info = this.translateService.instant('funnel.permit.info.nieuwsbrief');
        break;
      case DocumentType.VERZENDKOSTEN:
        localDocument.label = this.translateService.instant('funnel.permit.label.verzendkosten');
        localDocument.info = this.translateService.instant('funnel.permit.info.verzendkosten');
        break;
    }

    return localDocument;
  }
}
