import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Order } from '@model/order';
import { Permit } from '@model/permit';
import { KeycloakService } from 'keycloak-angular';
import { concatMap, from, map, Observable, of } from 'rxjs';


import { BaseService } from './base.service';
import { OrderService } from './order.service';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root',
})
export class DuplicateService extends BaseService {

  constructor(
    private readonly keycloakService: KeycloakService,
    private readonly profileService: ProfileService,
    private readonly orderService: OrderService,
    httpClient: HttpClient,
  ) {
    super(httpClient, '/funnel/invoice');
  }


  createDuplicateOrder(permitId: number, documentId: number): Observable<Order> {
    console.debug(`Trying to create duplicate order for permitId ${permitId} and documentId ${documentId}`);

    return from(this.keycloakService.isLoggedIn()).pipe(concatMap(isLoggedId => {
      if (isLoggedId) {
        return this.profileService.getRemoteProfile().pipe(map(remoteProfile => {
          this.profileService.setProfile(remoteProfile);
          if (remoteProfile.permits) {
            const duplicateDocument = remoteProfile.permits.find((permit: Permit) => permit.documentId === documentId && permit.number === permitId);
            if (duplicateDocument && duplicateDocument.duplicateRequestAllowed) {
              const order: Order = {
                permit: duplicateDocument,
                duplicate: true,
                totalPrice: duplicateDocument.duplicateRequestPrice,
                hsvId: duplicateDocument.organisation.hsvId,
              };
              order.permit.totalPrice = duplicateDocument.duplicateRequestPrice;
              console.debug(`Created duplicate order: ${order}`);
              this.orderService.setOrder(order);

              return order;
            }
          }
          console.debug('Cannot create duplicate order, user has no permits.');

          return null;
        }));
      } else {
        console.debug('Cannot create duplicate order, user is not logged in');

        return of(null);
      }
    }));
  }
}
