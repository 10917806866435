import { Component, OnInit } from '@angular/core';
import { Order } from '@model/order';
import { Session } from '@model/session';
import { OrderService } from '@service/order.service';
import { SessionService } from '@service/session.service';

@Component({
  selector: 'svnl-debug-footer',
  templateUrl: './debug-footer.component.html',
  styleUrls: ['./debug-footer.component.scss'],
})
export class DebugFooterComponent implements OnInit {
  session: Session;
  order: Order;

  constructor(
    private sessionService: SessionService,
    private orderService: OrderService,
  ) {
  }

  ngOnInit(): void {
    this.session = this.sessionService.getSession();
    this.order = this.orderService.getOrder();
  }

  updateSession(): void {
    this.session = this.sessionService.getSession();
    this.order = this.orderService.getOrder();
  }

  clearSession(): void {
    this.sessionService.clearSession();
  }
}
