import { Injectable } from '@angular/core';
import { Reseller } from '@model/reseller';
import { map, Observable, of, tap } from 'rxjs';

import { HsvService } from './hsv.service';
import { OrderService } from './order.service';

@Injectable({
  providedIn: 'root',
})
export class ResellerService {
  reseller: Reseller;

  constructor(
    private readonly hsvService: HsvService,
    private readonly orderService: OrderService,
  ) {
  }

  getReseller(): Observable<Reseller> {
    const resellerId = this.orderService.getResellerId();

    if (resellerId) {
      if (this.reseller && this.reseller.id === this.orderService.getResellerId()) {
        return of(this.reseller);
      } else {
        return this.setReseller(resellerId);
      }
    }

    return of(null);
  }

  setReseller(resellerId: number): Observable<Reseller> {
    return this.hsvService.findResellerById(resellerId).pipe(
      map(reseller => {
        this.reseller = reseller;

        return reseller;
      }),
    );
  }
}
