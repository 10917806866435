<div class="svnl-card">
  <header class="svnl-card-header">
    <svnl-header [wizardStep]="wizardStep" *ngIf="!isHomePage"></svnl-header>
    <svnl-header-home *ngIf="isHomePage"></svnl-header-home>
  </header>
  <main class="svnl-card-content">
    <router-outlet></router-outlet>
  </main>
  <footer class="svnl-debug" *ngIf="showDebugFooter">
    <svnl-debug-footer></svnl-debug-footer>
  </footer>
  <footer class="svnl-card-footer">
    <svnl-page-footer></svnl-page-footer>
  </footer>
</div>
