import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InvoiceOrder } from '@model/invoice-order';
import { OrderService } from '@service/order.service';
import { catchError, Observable, of } from 'rxjs';

import { BaseService } from './base.service';
import { ProfileService } from "@service/profile.service";

@Injectable({
  providedIn: 'root',
})
export class InvoiceService extends BaseService {
  order: InvoiceOrder;

  constructor(
    httpClient: HttpClient,
    private readonly orderService: OrderService,
    private readonly profileService: ProfileService
  ) {
    super(httpClient, 'funnel/invoice');
    this.orderService = orderService;
    this.profileService = profileService;
  }

  set setOrder(invoiceOrder: InvoiceOrder) {
    this.order = invoiceOrder;
  }

  get getOrder(): InvoiceOrder {
    return this.order;
  }

  handleInvoiceOrder(invoiceOrder: InvoiceOrder, hash: string): void {
    invoiceOrder.hash = hash;
    this.order = invoiceOrder;
    this.profileService.setProfile(invoiceOrder.profile);
    this.orderService.setOrder(invoiceOrder);
  }

  /** Find the invoiceOrder by hash
   * Returns local InvoiceOrder if it exists and hash matches, else returns remote InvoiceOrder
   * */
  findInvoiceOrderByHash(hash: string): Observable<InvoiceOrder> {
    if (hash !== undefined) {
      if (this.order !== undefined && this.order.hash === hash) {
        return of(this.order);
      } else {
        const options = { params: new HttpParams().set('hash', hash) };

        return this.httpClient.get<InvoiceOrder>(`${this.endpointUrl}`, options).pipe(catchError(err => {
          if (err?.status === 409){
            // Conflict indicates already paid invoice, we need to show this to the user;
            const paidInvoice = err.error as InvoiceOrder;
            paidInvoice.paid = true;

            return of(paidInvoice);
          }

          return of(null);
        }));
      }
    }

    return of(null);
  }

  isInvoiceOrderPaid(): boolean {
    return this.order?.paid;
  }

  /** Set the local invoiceOrder in the service;
   * */
  setInvoiceOrder(invoiceOrder: InvoiceOrder): void {
    this.order = invoiceOrder;
  }

}
