import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DocumentGuard } from './shared/guards/document.guard';
import { DuplicateGuard } from './shared/guards/duplicate.guard';
import { InvoiceGuard } from './shared/guards/invoice.guard';
import { LanguageParamGuard } from './shared/guards/language-param.guard';

const routes: Routes = [
  {
    path: 'home',
    title: 'VISpas',
    canActivate: [
      InvoiceGuard,
      DuplicateGuard,
      DocumentGuard,
    ],
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'order',

    loadChildren: () => import('./order/order.module').then(m => m.OrderModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'info',
    loadChildren: () => import('./info/info.module').then(m => m.InfoModule),
  },
  {
    path: 'termsconditions',
    canActivate: [
      LanguageParamGuard,
    ],
    loadChildren: () => import('./termsconditions/termsconditions.module').then(m => m.TermsconditionsModule),
  },
  {
    path: 'behavioral',
    canActivate: [
      LanguageParamGuard,
    ],
    loadChildren: () => import('./behavioral/behavioral.module').then(m => m.BehavioralModule),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}

