import { Injectable } from '@angular/core';
import { Settings } from '@model/settings';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root',
})
export class SettingsCookieService {

  settings: Settings;

  constructor(
    private cookieService: CookieService,
  ) {
  }

  getCookieSettings(): Settings {
    if (this.settings) {
      return this.settings;
    } else if (this.cookieService.get('svnl.funnel.settings')) {
      this.settings = JSON.parse(this.cookieService.get('svnl.funnel.settings'));

      return this.settings;
    }

    return null;
  }

  setCookieSettings(settings: Settings): void {
    this.settings = settings;
    // Cookie expiration set on cookieService allowed maximum days
    this.cookieService.set('svnl.funnel.settings', JSON.stringify(settings), 400);
  }

  setShow(show: boolean): void {
    if (this.getCookieSettings()) {
      this.settings.show = show;
    }
  }

  isTrackingEnabled(): boolean {
    const settings = this.getCookieSettings();

    return (settings) && settings.tracking;
  }

  isMarketingEnabled(): boolean {
    const settings = this.getCookieSettings();

    return (settings) && settings.marketing;
  }

}
