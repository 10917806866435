import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Settings } from '@model/settings';
import { NavigationService } from '@service/navigation.service';
import { SettingsCookieService } from '@service/settings-cookie.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { from } from 'rxjs';

import { environment } from '../../../environments/environment';

interface AnalyticsEvent {
  event: string,
  category?: string,
  label: string,
  pageName?: string

}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {

  settings: Settings;
  placedAdfOverview: boolean;
  private readonly gtmService = null;
  private hasGtmDom = false;

  constructor(
    private readonly settingsCookiesService: SettingsCookieService,
    private readonly navigationService: NavigationService,
    private readonly router: Router,
    private readonly injector: Injector,
  ) {
    if (environment.analytics.gtm) {
      this.gtmService = <GoogleTagManagerService>this.injector.get(GoogleTagManagerService);
      this.doGoogleTag();
    }
  }


  isTrackingEnabled(): boolean {
    return this.settingsCookiesService.isTrackingEnabled();
  }

  updateSettings(settings: Settings): void {
    if (settings) {
      this.settings = settings;
    }
    this.optIn();
  }

  optIn(): void {
    if (this.isTrackingEnabled()) {
      this.doHotJar();
      this.doGoogleAnalytics();
      this.doAdf();
    }
  }

  doHotJar(): void {
    this.setHotJar(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }

  doGoogleAnalytics(): void {
    this.setGoogleAnalytics(window, document);
  }

  doAdf(): void {
    this.setAdf(window, document);
  }

  getGoogleAnalyticsConfig(): string {
    return environment?.analytics?.google;
  }

  getHotjarConfig(): string {
    return environment?.analytics?.hotjar;
  }

  pageView(event: AnalyticsEvent): void {
    if (this.settingsCookiesService.isTrackingEnabled()) {
      if (environment.analytics.gtm) {
        this.gtmService.pushTag({ event: event.event, pageName: event.label });
      }
    }
  }

  trackPage(path: string): void {
    // if (this.getHotjarConfig()) {
    //   console.debug('Logging hj pageview');
    //   hj('stateChange', $location.url());
    // } else {
    //   console.debug('Skipping hj pageview')
    // }
    //
    // if (this.getGoogleAnalyticsConfig()) {
    //   console.debug('Logging ga pageview');
    //   ga('send', 'pageview', path);
    // } else {
    //   console.debug('Skipping ga pageview')
    // }
    //
    // if (this.getAdFormConfig()) {
    //   if (this.router.url === '/order/overview' && !this.placedAdfOverview) {
    //     this.doAdf();
    //     this.placedAdfOverview = true;
    //   }
    // } else {
    //   console.debug('Skipping adftrack pageview')
    // }
  }

  private getAdFormConfig(): string {
    return environment?.analytics?.adftrack;
  }

  private setHotJar(h: Window, o: Document, t: string, j: string, a?, r?): void {
    const id = this.getHotjarConfig();
    if (id) {
      (<any>h).hj = (<any>h).hj ||
        function () {
          ((<any>h).hj.q = (<any>h).hj.q || []).push(arguments);
        };
      (<any>h)._hjSettings = { hjid: id, hjsv: 5 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + (<any>h)._hjSettings.hjid + j + (<any>h)._hjSettings.hjsv;
      a.appendChild(r);
    } else {
      console.warn('Missing hotjar code');
    }

  }

  private setGoogleAnalytics(window, document): void {
    const id = this.getGoogleAnalyticsConfig();
    if (id) {
      (<any>window).ga = (<any>window).ga ||
        function () {
          ((<any>window).ga.q = (<any>window).ga.q || []).push(arguments);
        };
      (<any>window).ga.l = +new Date;
      (<any>window).ga('create', id, 'auto');
      (<any>window).ga('set', 'anonymizeIp', true);
      (<any>window).ga('send', 'pageview');

      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');

      script.async = true;
      script.src = 'https://www.google-analytics.com/analytics.js';
      head.appendChild(script);
    } else {
      console.warn('Missing GA code');
    }
  }

  private setAdf(window, document): void {
    const id = this.getAdFormConfig();
    if (id) {
      (<any>window)._adftrack = Array.isArray((<any>window)._adftrack) ?
        (<any>window)._adftrack : ((<any>window)._adftrack ? [(<any>window)._adftrack] : []);

      if (this.router.url === '/order/overview') {
        (<any>window)._adftrack.push({
          pm: id,
          divider: encodeURIComponent('|'),
          pagename: encodeURIComponent('Order overview'),
        });
      } else if ((this.router.url === '/order/payment/success')) {
        (<any>window)._adftrack.push({
          pm: id,
          divider: encodeURIComponent('|'),
          pagename: encodeURIComponent('Order succes'),
        });
      } else {
        (<any>window)._adftrack.push({
          pm: id,
        });
      }

      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');

      script.async = true;
      script.src = 'https://track.adform.net/serving/scripts/trackpoint/async/';
      head.appendChild(script);
    } else {
      console.warn('Missing ADF code');
    }
  }

  private doGoogleTag() {
    if (this.gtmService && !this.hasGtmDom){
      from(this.gtmService.addGtmToDom()).subscribe({
        next: res => {
          this.hasGtmDom = true;
          console.debug(res);
        },
        error: err => {
          console.error('[AnalyticsService] error while rendering gtm dom', err);
        },
      });
    } else {
      console.debug('Skipping Google Tag Manager, not enabled');
    }
  }
}
