import { Injectable } from '@angular/core';
import { Order } from '@model/order';
import { Organisation } from '@model/organisation';
import { Payment, PaymentServiceType } from '@model/payment';
import { Profile } from '@model/profile';
import { Session } from '@model/session';
import { CookieService } from 'ngx-cookie-service';

import { AbandonedCartService } from './abandoned-cart.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  session: Session;

  private static SESSION_KEY = 'session';

  constructor(
    private readonly abandonedCartService: AbandonedCartService,
    private readonly cookieService: CookieService,
  ) {
  }

  getSession(): Session {
    if (this.session == null) {
      let session = this.getLocalSession();
      if (session == null) {
        session = {};
      }
      this.session = session;
    }

    return this.session;
  }

  clearSession(): void {
    this.session = {};
    this.clearLocalSession();
  }

  setStep(wizardStep: number): void {
    this.session.step = wizardStep;
    this.setLocalSession();
  }

  getSessionStep(): number {
    if (this.session.step) {
      return this.session.step;
    }

    return 0;
  }

  getOrder(): Order {
    if (this.session == null) {
      this.session = {};
    } else if (this.session.order != null) {
      return this.session.order;
    } else {
      console.debug('No order in session');
    }

    return null;
  }

  setOrder(order: Order): void {
    if (this.getSession() == null) {
      const session = this.getSession();
      session.order = order;
      this.session = session;
    } else {
      this.getSession().order = order;
    }

    this.updateSession();
  }

  getProfile(): Profile {
    if (this.session == null) {
      this.session = {};
    } else if (this.session.profile != null) {
      return this.session.profile;
    } else {
      console.debug('No profile in session');
    }

    return null;
  }

  setProfile(profile: Profile): void {
    if (this.session == null) {
      this.session = {};
    }

    this.getSession().profile = { ...profile };

    this.updateSession();
  }

  updateProfile(id: string, profile: Profile): void {
    if (this.session == null) {
      this.session = {};
    }

    this.session.id = id;
    this.session.profile = profile;

    this.updateCookie();
  }

  getHsv(): Organisation {
    if (this.session != null && this.session.hsv != null) {
      return this.session.hsv;
    }

    return null;
  }

  setHsv(hsv: Organisation): void {
    if (this.session == null) {
      this.session = {};
    }

    this.session.hsv = hsv;
    this.updateSession();
  }

  setPaymentSettings(payment: Payment): void {

    if (!this.session) {
      this.getSession();
    }

    this.session.payment = {
      applePayToken: '',
      bank: '',
      bic: '',
      debit: false,
      debitBic: '',
      debitIban: '',
      name: '',
      service: PaymentServiceType.UNKNOWN,
    };

    if (payment.bank) {
      this.session.payment.bank = payment.bank;
      this.session.payment.bic = payment.bic;
    }

    if (payment.name) {
      this.session.payment.name = payment.name;
    }

    this.session.payment.debit = payment.debit;
    this.session.payment.service = payment.service;

    return this.updateSession();
  }

  setApplePayToken(applePayToken: string): Session {
    this.session.payment.applePayToken = applePayToken;

    return this.session;
  }


  private updateSession() {
    this.postAbandonedCart();
    this.setLocalSession();
  }

  private getLocalSession(): Session {
    const session = JSON.parse(sessionStorage.getItem(SessionService.SESSION_KEY));

    return session != null ? session : {} as Session;
  }

  private setLocalSession() {
    sessionStorage.setItem(SessionService.SESSION_KEY, JSON.stringify(this.session));
  }

  private clearLocalSession() {
    sessionStorage.removeItem(SessionService.SESSION_KEY);
  }

  private postAbandonedCart() {
    if (this.getProfile()) {
      this.abandonedCartService.updateAbandonedCart(this.session).subscribe(abandonedCart => {
        if (abandonedCart) {
          this.setAbandonedCartUuid(abandonedCart.message);
        }
      });
    }
  }

  private setAbandonedCartUuid(cartUuid: string) {
    if (this.session == null) {
      this.session = {};
    }

    this.session.cartUUID = cartUuid;

    this.updateCookie();
  }

  private updateCookie() {
    if (this.session) {
      this.cookieService.set('svnl.funnel.session', this.session.id);
    }
  }
}
